/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.title_wrapper{
  padding: 15px 0px 20px 0px;
}

.title{
  font-size: var(--title-font-size);
  color: var(--ion-color-primary-light);
  font-weight: bold;
  text-align: center;
}

.link{
  font-size: var(--link-font-size);
  color: var(--ion-color-primary);
  font-weight: bold;
  text-decoration: none;
}

.custom-toast{
  --background: white;
  --color: var(--ion-color-text);
}

.text-area-fix{
  textarea {
    min-height: 100%;
  }
}

.custom-popover{
  --max-height: 60%;
  --min-height: 300px;
  --min-width: 300px;
  --max-width: 80%;
}

.danger{
  color: var(--ion-color-danger) !important;
}

.bold{
  font-weight: bold !important;
}


ion-avatar{
  --border-radius: 0px ;
}

@keyframes rotateForward {
  from {transform: rotate(0deg);}
  to {transform: rotate(359deg);}
}

@keyframes rotateBackward {
  from {transform: rotate(0deg);}
  to {transform: rotate(-359deg);}
}

.picker-opt {
  color: var(--ion-color-primary) !important;
}
