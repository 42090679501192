// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: "Arial";
  --text-font-size: 1em;
  --title-font-size: 2em;
  --title-font-size-medium: 1.3em;
  --link-font-size: 1em;
  --nh-border-color: #CEF0EE;
  // --ion-backdrop-color:#9EE1DE;
  --ion-backdrop-opacity: 0.4;




  /** primary **/
  --ion-color-primary: #366475;
  --ion-color-primary-rgb: 54,100,117;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #305867;
  --ion-color-primary-tint: #4a7483;

  /** primary-light **/
  --ion-color-primary-light: #317c82;
  --ion-color-primary-light-rgb: 49,124,130;
  --ion-color-primary-light-contrast: #ffffff;
  --ion-color-primary-light-contrast-rgb: 255,255,255;
  --ion-color-primary-light-shade: #2b6d72;
  --ion-color-primary-light-tint: #46898f;

  .ion-color-primary-light {
    --ion-color-base: var(--ion-color-primary-light);
    --ion-color-base-rgb: var(--ion-color-primary-light-rgb);
    --ion-color-contrast: var(--ion-color-primary-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-light-shade);
    --ion-color-tint: var(--ion-color-primary-light-tint);
  }

  /** primary-light-light **/
  --ion-color-primary-light-light: #699aae;
  --ion-color-primary-light-light-rgb: 105,154,174;
  --ion-color-primary-light-light-contrast: #000000;
  --ion-color-primary-light-light-contrast-rgb: 0,0,0;
  --ion-color-primary-light-light-shade: #5c8899;
  --ion-color-primary-light-light-tint: #78a4b6;

  .ion-color-primary-light-light {
    --ion-color-base: var(--ion-color-primary-light-light);
    --ion-color-base-rgb: var(--ion-color-primary-light-light-rgb);
    --ion-color-contrast: var(--ion-color-primary-light-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-light-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-light-light-shade);
    --ion-color-tint: var(--ion-color-primary-light-light-tint);
  }

  /** primary-light-light-light **/
  --ion-color-primary-light-light-light: #e2f6f5;
  --ion-color-primary-light-light-light-rgb: 226,246,245;
  --ion-color-primary-light-light-light-contrast: #000000;
  --ion-color-primary-light-light-light-contrast-rgb: 0,0,0;
  --ion-color-primary-light-light-light-shade: #c7d8d8;
  --ion-color-primary-light-light-light-tint: #e5f7f6;

  .ion-color-primary-light-light-light {
    --ion-color-base: var(--ion-color-primary-light-light-light);
    --ion-color-base-rgb: var(--ion-color-primary-light-light-light-rgb);
    --ion-color-contrast: var(--ion-color-primary-light-light-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-primary-light-light-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-primary-light-light-light-shade);
    --ion-color-tint: var(--ion-color-primary-light-light-light-tint);
  }

  /** secondary **/
  --ion-color-secondary: #ff9f4e;
  --ion-color-secondary-rgb: 255,159,78;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e08c45;
  --ion-color-secondary-tint: #ffa960;

  /** secondary-light**/
  --ion-color-secondary-light: #ffcb66;
  --ion-color-secondary-light-rgb: 255,203,102;
  --ion-color-secondary-light-contrast: #000000;
  --ion-color-secondary-light-contrast-rgb: 0,0,0;
  --ion-color-secondary-light-shade: #e0b35a;
  --ion-color-secondary-light-tint: #ffd075;

  .ion-color-secondary-light {
    --ion-color-base: var(--ion-color-secondary-light);
    --ion-color-base-rgb: var(--ion-color-secondary-light-rgb);
    --ion-color-contrast: var(--ion-color-secondary-light-contrast);
    --ion-color-contrast-rgb: var(--ion-color-secondary-light-contrast-rgb);
    --ion-color-shade: var(--ion-color-secondary-light-shade);
    --ion-color-tint: var(--ion-color-secondary-light-tint);
  }

  /** tertiary **/
  --ion-color-tertiary: #88cfc5;
  --ion-color-tertiary-rgb: 136,207,197;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #78b6ad;
  --ion-color-tertiary-tint: #94d4cb;

  /** text **/
  --ion-color-text: #1c3545;
  --ion-color-text-rgb: 28,53,69;
  --ion-color-text-contrast: #ffffff;
  --ion-color-text-contrast-rgb: 255,255,255;
  --ion-color-text-shade: #192f3d;
  --ion-color-text-tint: #334958;

  .ion-color-text {
    --ion-color-base: var(--ion-color-text);
    --ion-color-base-rgb: var(--ion-color-text-rgb);
    --ion-color-contrast: var(--ion-color-text-contrast);
    --ion-color-contrast-rgb: var(--ion-color-text-contrast-rgb);
    --ion-color-shade: var(--ion-color-text-shade);
    --ion-color-tint: var(--ion-color-text-tint);
  }

   /** text-opac **/
   --ion-color-text-opac: #1c35458c;
   --ion-color-text-rgb-opac: 28,53,69;
   --ion-color-text-contrast-opac: #ffffff;
   --ion-color-text-contrast-opac-rgb: 255,255,255;
   --ion-color-text-shade-opac: #192f3d;
   --ion-color-text-tint-opac: #334958;

   .ion-color-text-opac {
  --ion-color-base: var(--ion-color-text-opac);
  --ion-color-base-rgb: var(--ion-color-text-opac-rgb);
  --ion-color-contrast: var(--ion-color-text-opac-contrast);
  --ion-color-contrast-rgb: var(--ion-color-text-opac-contrast-rgb);
  --ion-color-shade: var(--ion-color-text-opac-shade);
  --ion-color-tint: var(--ion-color-text-opac-tint);
}

  /** success **/
  --ion-color-success: var(--ion-color-tertiary);
  --ion-color-success-rgb: var(--ion-color-tertiary-rgb);
  --ion-color-success-contrast: var(--ion-color-tertiary-contrast);
  --ion-color-success-contrast-rgb: var(--ion-color-tertiary-contrast-rgb);
  --ion-color-success-shade: var(--ion-color-tertiary-shade);
  --ion-color-success-tint: var(--ion-color-tertiary-tint);

  /** warning **/
  --ion-color-warning: var(--ion-color-secondary);
  --ion-color-warning-rgb: var(--ion-color-secondary-rgb);
  --ion-color-warning-contrast: var(--ion-color-secondary-contrast);
  --ion-color-warning-contrast-rgb: var(--ion-color-secondary-contrast-rgb);
  --ion-color-warning-shade: var(--ion-color-secondary-shade);
  --ion-color-warning-tint: var(--ion-color-secondary-tint);;

  /** danger **/
  --ion-color-danger: #C9596A;
  --ion-color-danger-rgb: 201,89,106;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b14e5d;
  --ion-color-danger-tint: #ce6a79;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

    /** color steps borrowed from dark theme **/
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;


  // --ion-toolbar-background: transparent;

  ion-button{
    --border-radius: 7px;
    height: 48px;
    font-weight: bold;
  }


}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66,140,255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255,255,255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80,200,255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106,100,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244,245,248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152,154,162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34,36,40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}


